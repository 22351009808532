import React from 'react';
import axios from 'axios';
import './Contacts.css';
import Button from '../../Elements/Button/Button';

class Contacts extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      job: '',
      group: '',
      phone: '',
      email: '',
      message: '',
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json';
  }
  handleClick = () => {
    let error = 0;
    if(this.state.name === '') {
      error = 1;
      document.getElementById('name').classList.add('error');
    } else {
      document.getElementById('name').classList.remove('error');
    }
    if(this.state.email === '') {
      error = 1;
      document.getElementById('email').classList.add('error');
    } else {
      document.getElementById('email').classList.remove('error');
    }
    if(this.state.message === '') {
      error = 1;
      document.getElementById('message').classList.add('error');
    } else {
      document.getElementById('message').classList.remove('error');
    }

    if(error) return;

    let data = {
      name: this.state.name,
      job: this.state.job,
      group: this.state.group,
      phone: this.state.phone,
      email: this.state.email,
      message: this.state.message,
    };
    let str = `name=${data.name}&job=${data.job}&group=${data.group}&phone=${data.phone}&email=${data.email}&message=${data.message}`
    axios.get('send/index.php?'+str).then(res => {  }).catch((e) => {});
    document.getElementById('contacts__success').style.display = 'flex';
  }
  handleChange(field, e) {
    this.setState({[field]: e.target.value});
  }
  render() {
    return (
      <section id='contacts' className='contacts'>
        <h2 className='contacts__headline'>Будем рады помочь и вам!</h2>
        <div className='contacts__wrapper'>
          <div className='contacts__form' id='contact-form'>
            <div className='contacts__success' id='contacts__success'>
              <h2 className='contact__success-head'>Спасибо!</h2>
              <span className='lead contact__success-lead'>Ваше сообщение отправлено</span>
            </div>
            <div className='contacts__form-item'>
              <label htmlFor='name'>Имя и фамилия *</label>
              <input type='text' name='name' id='name' onChange={(e) => this.handleChange('name', e)}></input>
            </div>
            <div className='contacts__form-item'>
              <label htmlFor='job'>Должность</label>
              <input type='text' name='job' id='job' onChange={(e) => this.handleChange('job', e)}></input>
            </div>
            <div className='contacts__form-item contacts__form-item_full'>
              <label htmlFor='restaurant'>Ресторан / группа</label>
              <input type='text' name='restaurant' id='restaurant' onChange={(e) => this.handleChange('group', e)}></input>
            </div>
            <div className='contacts__form-item'>
              <label htmlFor='phone'>Телефон</label>
              <input type='text' name='phone' id='phone' onChange={(e) => this.handleChange('phone', e)}></input>
            </div>
            <div className='contacts__form-item'>
              <label htmlFor='email'>Почта *</label>
              <input type='text' name='email' id='email' onChange={(e) => this.handleChange('email', e)}></input>
            </div>
            <div className='contacts__form-item contacts__form-item_full'>
              <label htmlFor='message'>Собщение *</label>
              <textarea id='message' name='message' onChange={(e) => this.handleChange('message', e)}></textarea>
            </div>
            <Button style='button_third' handleClick={this.handleClick}>Отправить</Button>
            <div className='contacts_footer'>
            </div>
          </div>
          <span className='lead-small contacts__footer'>© 2019 iRestocracy</span>
        </div>
      </section>
    );
  }
}

export default Contacts;
