import React from 'react';
import './Security.css';
import Cta from '../Cta/Cta';
import Divider from '../Divider/Divider';


//Assets
import db from '../../Assets/Security/db.svg';
import shield from '../../Assets/Security/shield.svg';
import control from '../../Assets/Security/control.svg';

class Security extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id='security' className='security'>
        <h2 className='security__headline'>Безопасность</h2>
        <div className='security__items'>
          <div className='security__item'>
            <img src={db} width='62px' alt='Полный контроль над собственными данными' />
            <div className='security__heads'>
              <h3>Полный контроль<br/>над собственными данными</h3>
              <span className='subline'>Размещение системы<br />на сервере клиента</span>
            </div>
          </div>
          <div className='security__item'>
            <img src={shield} width='62px' alt='Шифрование данных' />
            <div className='security__heads'>
              <h3>Шифрование<br/>данных</h3>
              <span className='subline'>SSL сертификаты, шифрование QR-кодов<br/>виртуальных карт и другое</span>
            </div>
          </div>
          <div className='security__item'>
            <img src={control} width='62px' alt='Контроль подозрительной активности' />
            <div className='security__heads'>
              <h3>Контроль подозрительной<br />активности</h3>
              <span className='subline'>Уведомления службе безопасности, автоматическая рассылка отчетов</span>
            </div>
          </div>
        </div>
        <Cta />
        <Divider />
      </section>
    );
  }
}

export default Security;
