import React from 'react';

/*
handleClick
style
customClass
*/

class Button extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  getClassName() {
    
  }
  render() {
    return (
      <button
        onClick={this.props.handleClick}
        className={this.props.style + ' ' + this.props.customClass}
      >
        {this.props.children}
      </button>
    );
  }
}

export default Button;
