import React from 'react';
import './Crm.css';
import Cta from '../Cta/Cta';
import Divider from '../Divider/Divider';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

//Assets
import clients from '../../Assets/Crm/clients.png';
import orders from '../../Assets/Crm/orders.png';
import segments from '../../Assets/Crm/segments.png';
import exp from '../../Assets/Crm/export.png';
import analyze from '../../Assets/Crm/analyze.png';
import { throwStatement } from '@babel/types';


class Crm extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.content = [
      {
        image: clients,
        alt: 'Клиенты',
        text: 'База клиентов, личные данные, корпоративные клиенты, статистика',
        key: 0
      },
      {
        image: orders,
        alt: 'Заказы',
        text: 'История и детализация заказов, анализ предпочтений',
        key: 1
      },
      {
        image: segments,
        alt: 'Сегменты',
        text: 'Сегменты и группы клиентов - фильтрация и анализ по RFM и другим методикам',
        key: 2
      },
      {
        image: analyze,
        alt: 'Анализ',
        text: 'Когортный анализ, динамика перемещения клиентов по сегментам на основе актуальных данных',
        key: 3
      },
      {
        image: exp,
        alt: 'Экспорт',
        text: 'Импорт/экспорт клиентской базы',
        key: 4
      },
    ];
    this.state = {
      content: this.content[0],
      active: 0
    }
  }
  handleChange = (elem) => {
    this.setState({content: this.content[elem], active: elem})
  }
  componentDidMount() {
    this.content.map(el => {
      const img = new Image();
      img.src = el.image;
    })
  }
  render() {
    return (
      <section id='crm' className='crm'>
        <h2 className='crm__headline'>CRM-система</h2>
        <span className='lead crm__subline'>Центральная база для сбора и анализа всей информации<br />о вашей аудитории</span>
        <div className='crm__toggles'>
          <div className={ this.state.active === 0 ? 'crm__toggle crm__toggle_active' : 'crm__toggle' } onClick={() => this.handleChange(0)}>Клиенты</div>
          <div className={ this.state.active === 1 ? 'crm__toggle crm__toggle_active' : 'crm__toggle' } onClick={() => this.handleChange(1)}>Заказы</div>
          <div className={ this.state.active === 2 ? 'crm__toggle crm__toggle_active' : 'crm__toggle' } onClick={() => this.handleChange(2)}>Сегменты</div>
          <div className={ this.state.active === 3 ? 'crm__toggle crm__toggle_active' : 'crm__toggle' } onClick={() => this.handleChange(3)}>Анализ</div>
          <div className={ this.state.active === 4 ? 'crm__toggle crm__toggle_active' : 'crm__toggle' } onClick={() => this.handleChange(4)}>Экспорт</div>
        </div>
        <TransitionGroup className='crm__caption-wrapper'>
          <CSSTransition key={this.state.content.key} classNames='alert' timeout={300} unmountOnExit>
            <span className='lead_small crm__caption'>{this.state.content.text}</span>
          </CSSTransition>
        </TransitionGroup>
        <TransitionGroup className='crm__image-wrapper'>
          <CSSTransition key={this.state.content.key} classNames='alert' timeout={300} unmountOnExit>
            <img className='crm__image' src={this.state.content.image} alt={this.state.content.alt} width='980px' height='747px' />
          </CSSTransition>
        </TransitionGroup>
        <Cta />
        <Divider />
      </section>
    );
  }
}

export default Crm;
