import React from 'react';

/*
customClass
image
imageAlt
headline
subline
text
*/

class Card extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.image = React.createRef();
    this.heads = React.createRef();
    this.over = React.createRef();
  }
  handleOver = () => {
    if (this.props.text === undefined) return;
    this.image.current.classList.add('card__image_invisible');
    this.heads.current.classList.add('card__heads_invisible');
    this.over.current.classList.remove('card__over_invisible');
  }
  handleOut = () => {
    if (this.props.text === undefined) return;
    this.image.current.classList.remove('card__image_invisible');
    this.heads.current.classList.remove('card__heads_invisible');
    this.over.current.classList.add('card__over_invisible');
  }
  render() {
    return (
      <div
        onMouseOver={this.handleOver}
        onMouseOut={this.handleOut}
        className={'card ' + this.props.customClass}
      >
        <div className='card__image' ref={this.image}>
          <img src={this.props.image} alt={this.props.imageAlt} />  
        </div>
        <div className='card__heads' ref={this.heads}>
          <h3 dangerouslySetInnerHTML={{__html: this.props.headline}}></h3>
          <span className='subline' dangerouslySetInnerHTML={{__html: this.props.subline}}></span>
        </div>
        <div className='card__over card__over_invisible' ref={this.over}>
          <span className='small-text' dangerouslySetInnerHTML={{__html: this.props.text}}>
          </span>
        </div>
      </div>
    );
  }
}

export default Card;
