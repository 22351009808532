import React from 'react';
import './Clients.css';
import Divider from '../Divider/Divider';


//Assets
import novikov from '../../Assets/Clients/novikov.png';
import wrf from '../../Assets/Clients/wrf.png';
import cg from '../../Assets/Clients/cg.png';
import tashir from '../../Assets/Clients/tashir.png';
import west from '../../Assets/Clients/west.png';

class Clients extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className='clients'>
        <h2 className='clients__headline'>Клиенты</h2>
        <div className='clients__items'>
          <div className='clients__item'>
            <img src={novikov} width='140px' alt='Новиков' />
          </div>
          <div className='clients__item'>
            <img src={wrf} width='140px' alt='White Rabbit' />
          </div>
          <div className='clients__item'>
            <img src={cg} width='140px' alt='Crocus Group' />
          </div>
          <div className='clients__item'>
            <img src={tashir} width='140px' alt='Tashir' />
          </div>
          <div className='clients__item'>
            <img src={west} width='140px' alt='West' />
          </div>
        </div>
        <Divider />
      </section>
    );
  }
}

export default Clients;
