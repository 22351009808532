import React from 'react';
import './Mobileapps.css'
import Slider from '../Slider/Slider';
import Cta from '../Cta/Cta';
import Divider from '../Divider/Divider';

//Assets
import individ from '../../Assets/Mobileapps/individ.png';
import groups from '../../Assets/Mobileapps/groups.png';
import card from '../../Assets/Mobileapps/card.png';
import reserve from '../../Assets/Mobileapps/reserve.png';
import delivery from '../../Assets/Mobileapps/delivery.png';
// import reports from '../../Assets/Mobileapps/image2.png';

class Mobileapps extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.content = [
      {
        image: individ,
        title: 'Индивидуальный UX и дизайн с учетом вашего фирменного стиля, заточенный под ваши нужды и аудиторию',
        alt: 'Индивидуальный UX и дизайн с учетом вашего фирменного стиля, заточенный под ваши нужды и аудиторию',
        key: 0
      },
      {
        image: groups,
        title: 'Для ресторанных групп все рестораны в одном приложении - списком и на карте',
        alt: 'Для ресторанных групп все рестораны в одном приложении - списком и на карте',
        key: 1
      },
      {
        image: card,
        title: 'Виртуальная карта лояльности, баллы, история посещений и заказов',
        alt: 'Виртуальная карта лояльности, баллы, история посещений и заказов',
        key: 2
      },
      {
        image: reserve,
        title: '«Умные» резервы - подтверждение резерва по запросу без звонка, предупреждение об опоздании и другое',
        alt: '«Умные» резервы - подтверждение резерва по запросу без звонка, предупреждение об опоздании и другое',
        key: 3
      },
      {
        image: delivery,
        title: 'Доставка, предзаказ, любимые блюда и подборки',
        alt: 'Доставка, предзаказ, любимые блюда и подборки',
        key: 4
      },
      // {
      //   image: reports,
      //   title: 'Отзывы, push-уведомления, события, новости, акции и многое другое',
      //   alt: 'Отзывы, push-уведомления, события, новости, акции и многое другое'
      // },
    ];
  }
  render() {
    return (
      <section id="apps" className='mobileapps'>
        <div className='mobileapps__heads'>
          <h2>Мобильное приложение iOS и Android</h2>
          <span className='lead mobileapps__lead'>Без шаблонов и конструкторов, индивидуально под ваш брэнд.<br />Согласовываем с вами макеты и функционал</span>
        </div>
        <Slider content={this.content} prefix='_mobile'/>
        <Cta />
        <Divider />
      </section>
    );
  }
}

export default Mobileapps;
