import React from 'react';
import './App.css';
import Hero from './Components/Hero/Hero';
import Loyalty from './Components/Loyalty/Loyalty';
import Crm from './Components/Crm/Crm';
import Reserve from './Components/Reserve/Reserve';
import Delivery from './Components/Delivery/Delivery';
import Mobileapps from './Components/Mobileapps/Mobileapps';
import Marketing from './Components/Marketing/Marketing';
import Security from './Components/Security/Security';
import Clients from './Components/Clients/Clients';
import Contacts from './Components/Contacts/Contacts';

function App() {
  return (
    <div>
      <Hero />
      <Loyalty />
      <Crm />
      <Reserve />
      <Delivery />
      <Mobileapps />
      <Marketing />
      <Security />
      <Clients />
      <Contacts />
    </div>
  );
}

export default App;
