import React from 'react';
import './Divider.css';

class Divider extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='divider'></div>
    );
  }
}

export default Divider;
