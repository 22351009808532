import React from 'react';
import './Slider.css';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

class Slider extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.content[0].image,
      alt: this.props.content[0].alt,
      key: this.props.content[0].key
    }
    this.interval = null;
  }
  handlerChange = (index, e) => {
    clearInterval(this.interval);
    this.setState({
      image: this.props.content[index].image,
      alt: this.props.content[index].alt,
      key: this.props.content[index].key
    })
  }
  componentDidMount() {
    this.props.content.map(el => {
      const img = new Image();
      img.src = el.image;
    });
    this.interval = setInterval(() => {
      let state = this.state;
      let key = state.key < this.props.content.length -1 ? state.key + 1 : 0;
      this.setState({
        image: this.props.content[key].image,
        alt: this.props.content[key].alt,
        key: key
      })
    }, 2500)
  }
  render() {
    return (
      <div className='slider'>
        <TransitionGroup className={'slider__image-wrapper'}>
          <CSSTransition key={this.state.key} classNames='alert' timeout={300} unmountOnExit>
            <img className='slider__image' src={this.state.image} alt={this.state.alt} width='440px' />
          </CSSTransition>
        </TransitionGroup>
        <div className={'slider__items'}>
          {this.props.content.map((el, index) => {
            return (
            <div key={index} onClick={(e) => this.handlerChange(el.key, e)} className={this.state.key === el.key ? 'slider__item slider__item_active' : 'slider__item'}>
              <div className='slider__dot'></div>
              <div className='slider__texts'>
                <h3>{el.title}</h3>
                <p>{el.text}</p>
              </div>
            </div>            
            )
          })}
        </div>
      </div>
    );
  }
}

export default Slider;
