import React from 'react';
import './Cta.css';
import Button from '../../Elements/Button/Button';

class Cta extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  handleScroll(item, e) {
    e.preventDefault();
    document.getElementById(item).scrollIntoView({behavior: "smooth", block: "start"});
  }
  render() {
    return (
      <div className='cta'>
        <a href='/#' onClick={(e) => this.handleScroll('contacts', e)}><Button style='button_third'>Оставить заявку</Button></a>
      </div>
    );
  }
}

export default Cta;
