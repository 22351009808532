import React from 'react';
import './Loyalty.css';
import Card from '../../Elements/Card/Card';
import Cta from '../Cta/Cta';
import Divider from '../Divider/Divider';


//Assets
import levels from '../../Assets/Loyalty/levels.svg';
import clock from '../../Assets/Loyalty/clock.svg';
import events from '../../Assets/Loyalty/events.svg';
import card from '../../Assets/Loyalty/card.svg';
import virtual from '../../Assets/Loyalty/virtual.svg';
import logos from '../../Assets/Loyalty/logos.png';

class Loyalty extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id='loyalty' className='loyalty'>
        <h2>Программа лояльности</h2>
        <span className='lead loyalty__lead'>Вознаграждайте ваших гостей баллами и скидками,<br />принимайте карты лояльности на любых носителях</span>
        <div className='loyalty__cards'>
          <Card
          customClass='loyalty__card'
          image={levels}
          imageAlt='Уровни лояльности'
          headline='Уровни<br />лояльности'
          text='<b>Бонусная, дисконтная и смешанная системы лояльности.</b><br>Прогрессивная или плоская шкала, настройка ограничений и исключение отдельных категорий меню.'
          />
          <Card
          customClass='loyalty__card'
          image={virtual}
          imageAlt='Виртуальная карта'
          headline='Виртуальная<br />карта'
          text='<b>Виртуальная карта лояльности гостя.</b><br>Мобильное приложение с QR-кодами, историей заказов и начислений/списаний баллов.'
          />
          <Card
          customClass='loyalty__card'
          image={card}
          imageAlt='Пластиковая карта'
          headline='Пластиковая<br />карта'
          text='<b>Пластиковая карта лояльности.</b><br>Гость заполняет форму через планшет или сайт - данные попадают в CRM-систему и присваивается номер карты.'
          />
          <Card
          customClass='loyalty__card'
          image={events}
          imageAlt='Акции'
          headline='Акции'
          text='<b>Акции.</b><br>Спецпредложения, реферальная программа (“пригласи друга”), приветственные бонусы, штамп-карты и др.'
          />
          <Card
          customClass='loyalty__card'
          image={clock}
          imageAlt='Счастливые часы'
          headline='Счастливые<br />часы'
          text='<b>Счастливые часы с повышенным начислением баллов.</b><br>Стимуляция спроса в “мертвые часы” повышенным коэффициентом начисления баллов.'
          />                                        
        </div>
        <div className='loyalty__integration'>
          <span className='small-text loyalty__integration-text'>Интеграция с </span>
          <img src={logos} width='160px' alt='r_keeper и iiko' />
        </div>
        <Cta />
        <Divider />
      </section>
    );
  }
}

export default Loyalty;
