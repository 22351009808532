import React from 'react';
import './Marketing.css';
import Card from '../../Elements/Card/Card';
import Cta from '../Cta/Cta';
import Divider from '../Divider/Divider';


//Assets
import sms from '../../Assets/Marketing/sms.svg';
import messengers from '../../Assets/Marketing/messengers.svg';
import push from '../../Assets/Marketing/push.svg';
import mail from '../../Assets/Marketing/mail.svg';

class Marketing extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id='marketing' className='marketing'>
        <h2>Маркетинг</h2>
        <span className='lead marketing__lead'>Коммуницируйте с клиентом через привычные для них платформы,<br />используйте наиболее эффективные каналы связи</span>
        <div className='marketing__cards'>
          <Card
          customClass='marketing__card'
          image={sms}
          imageAlt='Смс-рассылки'
          headline='Смс-рассылки'
          subline='Автоматические и мануальные'
          />
          <Card
          customClass='marketing__card'
          image={messengers}
          imageAlt='Мессенджеры'
          headline='Мессенджеры'
          subline='WhatsApp, Telegram,<br />Viber, Facebook'
          />
          <Card
          customClass='marketing__card'
          image={push}
          imageAlt='Push-уведомления'
          headline='Push-уведомления'
          subline='Через мобильное приложение'
          />
          <Card
          customClass='marketing__card'
          image={mail}
          imageAlt='Email рассылки'
          headline='Email рассылки'
          subline='Выборка клиентов и сегментов'
          />
        </div>
        <Cta />
        <Divider />
      </section>
    );
  }
}

export default Marketing;
