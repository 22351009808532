import React from 'react';
import './Hero.css';
import Button from '../../Elements/Button/Button';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

//Assets
import logo from '../../Assets/Hero/logo.svg';

class Hero extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.headlines = [
      {
        title: 'Узнать<br/>гостя',
        index: 0
      },
      {
        title: 'Построить отношения',
        index: 1
      },
      {
        title: 'Не отпустить',
        index: 2
      },
      {
        title: 'Вознаградить лояльность',
        index: 3
      },
      {
        title: 'Обеспечить комфорт',
        index: 4
      }
    ];
    this.state = {
      headline: this.headlines[0]
    };
  }
  handleScroll(item, e) {
    e.preventDefault();
    document.getElementById(item).scrollIntoView({behavior: "smooth", block: "start"});
  }
  do() {
    let index = this.state.headline.index;
    index < this.headlines.length - 1 ? this.setState({headline: this.headlines[index + 1]}) : this.setState({headline: this.headlines[0]});
  }
  componentDidMount() {
    setInterval(() => {
      this.do();
    }, 2500);
  }
  render() {
    return (
      <section className='hero'>
        <div className='hero__header'>
          <img src={logo} alt='iRestocracy' width='150px' />
          <div className='hero__menu'>
            <a href='/#' className='hero__menu-item' onClick={(e) => this.handleScroll('loyalty', e)}>Лояльность</a>
            <a href='/#' className='hero__menu-item' onClick={(e) => this.handleScroll('crm', e)}>CRM</a>
            <a href='/#' className='hero__menu-item' onClick={(e) => this.handleScroll('reserve', e)}>Резервы</a>
            <a href='/#' className='hero__menu-item' onClick={(e) => this.handleScroll('delivery', e)}>Доставка</a>
            <a href='/#' className='hero__menu-item' onClick={(e) => this.handleScroll('apps', e)}>Приложения</a>
            <a href='/#' className='hero__menu-item' onClick={(e) => this.handleScroll('marketing', e)}>Маркетинг</a>
            <a href='/#' className='hero__menu-item' onClick={(e) => this.handleScroll('security', e)}>Безопасность</a>
          </div>
          <div className='hero__contact-link'>
            <a href='/#' className='hero__menu-item' onClick={(e) => this.handleScroll('contacts', e)}>Контакты</a>
          </div>
        </div>
        <div className='hero__content'>
          <h2 className='hero__subline'>Инструменты для ресторанов,<br />которые помогут:</h2>
          <TransitionGroup className='hero__wrapper'>
            <CSSTransition key={this.state.headline.index} classNames='alert' timeout={400} unmountOnExit>
              <h1 className='hero__headline' dangerouslySetInnerHTML={{__html: this.state.headline.title}}></h1>
            </CSSTransition>
          </TransitionGroup>
          <a href='/#' onClick={(e) => this.handleScroll('loyalty', e)}><Button style='button_second'>Подробнее</Button></a>
        </div>
      </section>
    );
  }
}

export default Hero;
