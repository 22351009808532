import React from 'react';
import './Delivery.css';
import Slider from '../Slider/Slider';
import Cta from '../Cta/Cta';

//Assets
import order from '../../Assets/Delivery/order.png';
import integration from '../../Assets/Delivery/integration.png';
import workplace from '../../Assets/Delivery/operator.png';
import app from '../../Assets/Delivery/courier.png';

class Delivery extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.content = [
      {
        image: order,
        title: 'Заказ через сайт и мобильное приложение',
        alt: 'Заказ через сайт и мобильное приложение',
        text: 'Онлайн меню с поиском, подборками, онлайн оплатой и другое',
        key: 0
      },
      {
        image: integration,
        title: 'Полная интеграция с r_keeper и iiko',
        alt: 'Полная интеграция с r_keeper и iiko',
        text: 'Получение актуального меню, модификаторов и стоп-листов, отправка заказов в систему без ручного ввода, применение программы лояльности',
        key: 1
      },
      {
        image: workplace,
        title: 'Рабочая станция операторов доставки и колл-центра',
        alt: 'Рабочая станция операторов доставки и колл-центра',
        text: 'Подтверждение и прием заказов, удаленная отправка на кухню, отслеживание статусов, управление курьерами',
        key: 2
      },
      {
        image: app,
        title: 'Приложения для курьеров и комплектовщиков',
        alt: 'Приложения для курьеров и комплектовщиков',
        text: 'Отслеживание статуса заказа и местоположения курьера, построение маршрута, чек-листы для персонала',
        key: 3
      }
    ];
  }
  render() {
    return (
      <section id='delivery' className='delivery'>
        <div className='delivery__heads'>
          <h2>Доставка и предзаказ</h2>
          <span className='lead delivery__lead'>Полная автоматизация доставки - от онлайн меню для заказа<br/>до отслеживания локации курьера и рабочим местом оператора</span>
        </div>
        <Slider content={this.content} prefix='_delivery' />
        <Cta />
      </section>
    );
  }
}

export default Delivery;
