import React from 'react';
import './Reserve.css';
import Slider from '../Slider/Slider';
import Cta from '../Cta/Cta';

//Assets
import sources from '../../Assets/Reserve/sources.svg';
import coin from '../../Assets/Reserve/coin.svg';
import profile from '../../Assets/Reserve/profile.svg';
import shield from '../../Assets/Reserve/shield.svg';
import res from '../../Assets/Reserve/reserve.png';
import status from '../../Assets/Reserve/status.png';
import host from '../../Assets/Reserve/host.png';

class Reserve extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.content = [
      {
        image: res,
        title: 'Форма приема резервов',
        alt: 'Форма приема резервов',
        key: 0
      },
      {
        image: status,
        title: 'Управление статусами резервов',
        alt: 'Управление статусами резервов',
        key: 1
      },
      {
        image: host,
        title: 'Приложение хостес',
        alt: 'Приложение хостес',
        key: 2
      }
    ];
  }
  render() {
    return (
      <section id='reserve' className='reserve'>
        <div className='reserve__heads'>
          <h2>Резервы столов - полная автоматизация</h2>
          <span className='lead reserve__lead'>Принимайте резервы с разных платформ, управляйте посадками через приложение хостес на iPad, аккумулируйте все данные в CRM</span>
        </div>
        <div className='reserve__items'>
          <div className='reserve__item'>
            <img src={sources} width='70px' className='reserve__item-image' alt='Принимайте резервы со всех платформ' />
            <p>Принимайте резервы со всех платформ</p>
          </div>
          <div className='reserve__item'>
            <img src={coin} width='80px' className='reserve__item-image' alt='Не платите за каждую входящую заявку' />
            <p>Не платите за каждую входящую заявку</p>
          </div>
          <div className='reserve__item'>
            <img src={profile} width='80px' className='reserve__item-image' alt='Новые клиенты добавляются базу. Автозаполнение данных клиента по номеру телефона' />
            <p>Новые клиенты добавляются базу. Автозаполнение данных клиента по номеру телефона</p>
          </div>
          <div className='reserve__item'>
            <img src={shield} width='80px' className='reserve__item-image' alt='Данные о клиентах поступают к вам напрямую и хранятся только у вас' />
            <p>Данные о клиентах поступают к вам напрямую и хранятся только у вас</p>
          </div>          
        </div>
        <Slider content={this.content} prefix='_reserve' />
        <Cta />     
      </section>
    );
  }
}

export default Reserve;
